import React from 'react';
import '../../assets/css/audiolystyles.css';
import '../../assets/css/styles.scss'
import Modal from '@material-ui/core/Modal';
import PropTypes from 'prop-types';
import { FormControl, InputLabel, Select, MenuItem, CircularProgress, Tooltip, TextField, IconButton, Button } from '@material-ui/core';
import Dropzone from 'react-dropzone-uploader';
import { useState, useEffect, useRef } from 'react';
import { getModelTranscriptionResource1, getUsersFolder, addParentFolder, getUserTeamsRecordings } from "services/api";
import { useAuth } from '../../services/authContext';
import { getCookie } from "components/Utils/cookies";
import Tree from 'rc-tree';
import { CreateNewFolderRounded, SaveRounded, ClearRounded } from "@material-ui/icons";

const CHUNK_SIZE = 1024 * 1024;


const UploadFile = ({ isOpen, handleClose, selectedFile }) => {
    const [selectedModel, setSelectedModel] = useState('');
    const [models, setModels] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showProgressBar, setShowProgressBar] = useState(true);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [isUploading, setUpLoading] = useState(false);
    const dropzoneRef = useRef(null);
    const { user } = useAuth();
    const [usersFolder, setUsersFolder] = useState([]);
    const [treeData, setTreeData] = useState([]);
    const [selectedFolderId, setSelectedFolderId] = useState(0);
    const [addingFolderParentId, setAddingFolderParentId] = useState(null);
    const [editingFolderId, setEditingFolderId] = useState(null);
    const [parentFolderValue, setParentFolderValue] = useState('');
    const [userTeamRecordingOption, setUserTeamRecordingOption] = useState([]);
    const [selecteduserTeamRecordingOption, setSelecteduserTeamRecordingOption] = useState('');
    const [isMSAccount, setIsMSAccount] = useState(false);

    const LoadingIndicator = () => (
        <div className="uploading-indicator">
            {isUploading && <p style={{ fontWeight: 'bolder', fontSize: '20px' }}>Uploading<span className="dot">.</span><span className="dot">.</span><span className="dot">.</span></p>}
        </div>
    );
    const fetchModels = async () => {
        try {
            setLoading(true);
            const modelsData = await getModelTranscriptionResource1();
            const sortedModels = modelsData.sort((a, b) =>
                b.displayName.localeCompare(a.displayName)
            );

            setModels(sortedModels);
            console.log("Fetched models:", sortedModels);
            setSelectedModel(sortedModels[2]?.self || '');
            console.log("Selected model:", selectedModel);
        } catch (error) {
            console.error("Error fetching models:", error);
        } finally {
            setLoading(false);
        }
    };

    const fetchRecordedTeamsMeeting = async() =>
        {
            let isMsLoggedIn = localStorage.getItem('isMSAccountLoggedIn');
            
            setLoading(true);
          try{

            if(isMsLoggedIn == 'true')
            {
                setIsMSAccount(true);
                let token = localStorage.getItem('msToken');
                let teamsRecording = await getUserTeamsRecordings(token);
    
                if(teamsRecording != 'fetchRecordedTeamsMeeting')
                {
                console.log(teamsRecording);
                
                const userTeamRecordingDetails = teamsRecording.value.map((item) => ({
                    name: item.name, 
                    size: item.size,
                    webUrl: item['@microsoft.graph.downloadUrl']

                }));

                setUserTeamRecordingOption(userTeamRecordingDetails);

                }
                else
                {
                   console.log("Recordings folder not found.");
                }
            }
            else
            {
                setIsMSAccount(false);
            }
          }
          catch(err)
          {
            console.error(err);
          }
          finally
          {
            setLoading(false);
          }
        }
  

    useEffect(() => {
        fetchModels();
        console.log('test');
        
        fetchRecordedTeamsMeeting();

    }, []);

    const uploadUserTeamsRecording = async() => 
    {
        setUpLoading(true);
      console.log(selecteduserTeamRecordingOption);
      const token = getCookie('jwtToken');

    const completeResponse = await fetch(process.env.REACT_APP_API_URL + "/api/Upload/CompleteUpload", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
            FileName: selecteduserTeamRecordingOption.name,
            FileSize: selecteduserTeamRecordingOption.size,
            AudioTitleName: "My Audio Title",
            UserEmail: user.email,
            UserName: user.name,
            CustomModel: selectedModel,
            FolderId: selectedFolderId,
            IsUploadFromOneDrive: true,
            FileUrl: selecteduserTeamRecordingOption.webUrl
        })
    });

    console.log("Complete response1:", completeResponse);
    if (!completeResponse.ok) {
        handleClose();
        console.error("Failed to complete upload");
        return;
    }
    const responseData = await completeResponse.json();
    console.log("Complete response:", responseData);
    setUpLoading(false);
    setUploadProgress(0);
    return { url: 'https://httpbin.org/post' };
      //call api to upload it
    }

    const uploadFile = async ({ file }) => {
        setUpLoading(true);
        //const apiUrl = "https://localhost:7218" // process.env.REACT_APP_API_URL;
        const fileSize = file.size;
        const fileName = file.name;
        const totalChunks = Math.ceil(fileSize / CHUNK_SIZE);
        let completedChunks = 0;

        const token = getCookie('jwtToken');

        for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
            const start = chunkIndex * CHUNK_SIZE;
            const end = Math.min(start + CHUNK_SIZE, fileSize);
            const chunk = file.slice(start, end);

            const formData = new FormData();
            formData.append("chunk", chunk);
            formData.append("fileName", fileName);
            formData.append("fileSize", fileSize);
            formData.append("chunkIndex", chunkIndex);
            formData.append("totalChunks", totalChunks);

            try {
                const response = await fetch(process.env.REACT_APP_API_URL + "/api/Upload/UploadChunk", {
                    method: "POST",
                    body: formData,
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (!response.ok) {
                    console.error("Failed to upload chunk:", chunkIndex);
                    setUpLoading(false);
                    setUploadProgress(0); // Reset progress on failure
                    return; // Stop if chunk upload fails
                }

                completedChunks++;
                const progressPercentage = Math.floor((completedChunks / totalChunks) * 100);
                setUploadProgress(progressPercentage); // Update progress
            } catch (error) {
                console.error("Error uploading chunk:", error);
                setUpLoading(false);
                setUploadProgress(0); // Reset progress on error
                return; // Stop on error
            }
        }

        const completeResponse = await fetch(process.env.REACT_APP_API_URL + "/api/Upload/CompleteUpload", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                FileName: fileName,
                FileSize: fileSize,
                AudioTitleName: "My Audio Title",
                UserEmail: user.email,
                UserName: user.name,
                CustomModel: selectedModel,
                FolderId: selectedFolderId,
                IsUploadFromOneDrive: false,
                FileUrl: ""
            })
        });
        console.log("Complete response1:", completeResponse);
        if (!completeResponse.ok) {
            handleClose();
            console.error("Failed to complete upload");
            return;
        }
        const responseData = await completeResponse.json();
        console.log("Complete response:", responseData);
        setUploadProgress(0);
        return { url: 'https://httpbin.org/post' };
    };
    const handleChangeStatus = ({ meta, file }, status) => {
        console.log('File status:', status, 'Meta:', meta, 'File:', file);
        if (status === 'done') {
            setUpLoading(false);
            setShowProgressBar(false);
            if (dropzoneRef.current) {
                if (typeof dropzoneRef.current.removeAllFiles === 'function') {
                    dropzoneRef.current.removeAllFiles();
                } else {
                    removeFile(file);
                }
            }
            handleClose();
            setTimeout(() => {
                const progressBar = document.querySelector('.dz-progress');
                if (progressBar) {
                    progressBar.style.display = 'none';
                }
            }, 100);
        }
        if (status === 'uploading') {
            handleClose();
            setUpLoading(false);
            setShowProgressBar(false);
        }
    };
    const removeFile = (file) => {
        const fileElement = document.querySelector(`[data-dz-file="${file.name}"]`);
        if (fileElement) {
            fileElement.remove();
        }
    };
    //---------------------------------Move File-------------------------------------
    function buildTree(data) {
        const idToNodeMap = {};
        const resultTree = [];

        data.forEach((item) => {

            // Map each item by Id for easy reference
            idToNodeMap[item.id] = {
                title: (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {editingFolderId === item.id ? (
                            <TextField
                                defaultValue={item.name}
                                size="small"
                                onBlur={(e) => handleRenameFolder(e, item.id)}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') handleRenameFolder(e, item.id);
                                }}
                                autoFocus
                            />
                        ) : (
                            <>
                                {item.name}
                                {item.isParent && (
                                    <>
                                        <Tooltip title="Add a folder" arrow placement='right' >

                                            <IconButton
                                                size="small"
                                                color="default"
                                                onClick={(e) => handleAddSubFolder(e, item.id)}
                                                style={{ marginLeft: 8 }}>
                                                <CreateNewFolderRounded fontSize="small" />
                                            </IconButton>

                                        </Tooltip>
                                        {/* <IconButton
                      size="small"
                      color="secondary"
                      onClick={(e) => handleEditFolder(e, item.id)}
                      style={{ marginLeft: 4 }}>
                        <Edit fontSize="small" />
                    </IconButton> */}
                                    </>
                                )}
                            </>
                        )}

                    </div>
                ),
                key: item.id,
                isLeaf: !item.isParent, // Mark files (not folders) as leaf nodes
                children: [
                    ...(item.files && item.files.length > 0
                        ? item.files.map((file) => ({
                            title: file.name,
                            key: `file-${file.id}`,
                            isLeaf: true
                        }))
                        : [])
                ]
            };
        });

        data.forEach((item) => {
            if (item.parentId) {
                idToNodeMap[item.parentId].children.push(idToNodeMap[item.id]);
            } else {
                resultTree.push(idToNodeMap[item.id]);
            }
        });

        // Add TextField for the new folder if addingFolderParentId is set
        if (addingFolderParentId && idToNodeMap[addingFolderParentId]) {
            idToNodeMap[addingFolderParentId].children.push({
                title: (
                    <TextField
                        size="small"
                        placeholder="New folder name"
                        onBlur={(e) => handleCreateFolder(e, addingFolderParentId)}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') handleCreateFolder(e, addingFolderParentId);
                        }}
                        autoFocus
                    />
                ),
                key: `new-folder-${addingFolderParentId}`,
                isLeaf: true
            });
        }

        return resultTree;
    }

    const handleRenameFolder = (e, folderId) => {
        const newName = e.target.value.trim();
        console.log(newName);
        console.log(folderId);
        // if (newName) {
        //   console.log(`Rename folder with ID: ${folderId} to: ${newName}`);
        //   setUsersFolder((prevData) =>
        //     prevData.map((item) =>
        //       item.id === folderId ? { ...item, name: newName } : item
        //     )
        //   );
        // }
        setEditingFolderId(null);
    };
    const handleAddSubFolder = (e, parentId) => {
        e.stopPropagation();
        setAddingFolderParentId(parentId);
    };

    const handleCreateFolder = async (e, parentId) => {
        if (e.target.value == '') {
            setAddingFolderParentId(null);
            return;
        }

        const folderName = e.target.value.trim();
        if (folderName) {

            const payload =
            {
                id: 0,
                name: folderName,
                isParent: true,
                parentId: parentId,
                userId: 0,
                email: user.email,
                fileId: selectedFile.id
            };

            var response = await addParentFolder(payload);
            setUsersFolder(response);

            const updatedTreeData = buildTree(response);
            setTreeData(updatedTreeData);
        }

        setAddingFolderParentId('');
    };
    const onSelect = (selectedKeys, info) => {
        const { node } = info;
        if (!node.isLeaf) {
            // Only set the ID if it’s a folder (not a file)

            if (selectedKeys.length == 0) {
                setSelectedFolderId('');
                return;
            }

            const selectedFolderId = selectedKeys[0];
            console.log('Selected Folder ID:', selectedFolderId);
            setSelectedFolderId(selectedFolderId);
        } else {
            setSelectedFolderId('');
        }

    };
    const onExpand = (expandedKeys) => {
        console.log('expanded', expandedKeys);
    };
    // const handleUpdateFileFolder = async () => {
    //     if (selectedFolderId == '') {
    //         console.log('No selected folder');
    //         return;
    //     }

    //     const payload =
    //     {
    //         id: selectedFolderId,
    //         name: parentFolderValue,
    //         isParent: false,
    //         parentId: null,
    //         userId: 0,
    //         email: user.email,
    //         fileId: selectedFile.id
    //     };

    //     var response = await updateFileFolder(payload);

    //     setUsersFolder(response);

    //     const updatedTreeData = buildTree(response);
    //     setTreeData(updatedTreeData);
    // }
    const handleAddParentFolder = async () => {
        const payload =
        {
            id: 0,
            name: parentFolderValue,
            isParent: true,
            parentId: null,
            userId: 0,
            email: user.email,
            fileId: 0
        };

        var response = await addParentFolder(payload);

        setUsersFolder(response);

        const updatedTreeData = buildTree(response);
        setTreeData(updatedTreeData);

        setParentFolderValue('');

    }
    useEffect(() => {
        const fetchUsersFolder = async () => {
            setLoading(true);

            try {
                if (user == null) {
                    return console.error('user is empty');
                }

                const result = await getUsersFolder(user.email);

                setUsersFolder(result);
            }
            catch (error) {
                console.error("Error fetching folders:", error);
            }
            finally {
                setLoading(false);
            }
        };

        fetchUsersFolder();
    }, [user]);
    useEffect(() => {
        const transformedData = buildTree(usersFolder);
        setTreeData(transformedData);

    }, [usersFolder, addingFolderParentId, editingFolderId])

     //---------------------------------end Move File-------------------------------------
    return (
        <div>
            <Modal
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                BackdropProps={{
                    onClick: (e) => e.stopPropagation(),
                }}
            >
                <div className="modalContainer">
                    <div className="modalContentUpload">
                        <button onClick={handleClose} className="modalBtnClose">
                            &times;
                        </button>
                        <div style={{ 
                            marginBottom: '10px', 
                            width: isMSAccount ? '100%' : '50%',
                            display: 'flex' }}>
                            <FormControl fullWidth margin="normal">
                                <InputLabel id="modelSelectLabel">Select Custom Model</InputLabel>
                                <Select
                                    labelId="modelSelectLabel"
                                    id="modelSelect"
                                    value={selectedModel}
                                    onChange={(e) => setSelectedModel(e.target.value)}
                                >
                                    {loading ? (
                                        <MenuItem disabled>
                                            <CircularProgress size={24} style={{ marginRight: '10px' }} />
                                            Loading models...
                                        </MenuItem>
                                    ) : (
                                        models.map((model) => (
                                            <MenuItem key={model.self} value={model.self}>
                                                {model.displayName}
                                            </MenuItem>
                                        ))
                                    )}
                                </Select>
                            </FormControl>
                        <FormControl fullWidth margin="normal" style={{marginLeft: '30px'}}>
                        <InputLabel id="teamRecordingsSelectLabel">Select Teams Recording</InputLabel>
                            <Select 
                                value={selecteduserTeamRecordingOption} 
                                onChange={(e) => setSelecteduserTeamRecordingOption(e.target.value)} 
                                labelId="teamRecordingsSelectLabel">

                                {loading ? (
                                <MenuItem disabled>
                                    <CircularProgress size={24} style={{ marginRight: '10px' }} />
                                    Loading models...
                                </MenuItem>
                                ) : (

                                    userTeamRecordingOption.map((teamRecording, index) => (
                                    <MenuItem key={index} value={teamRecording}>
                                        {teamRecording.name}
                                    </MenuItem>
                                    ))

                                )}
                        </Select>
                        <Button onClick={uploadUserTeamsRecording}>Upload</Button>
                        </FormControl>
                        </div>
                        <div className="modalContentBody">
                            <div className="leftPane">
                                <TextField
                                    size="small"
                                    variant="outlined"
                                    value={parentFolderValue}
                                    onChange={(event) => setParentFolderValue(event.target.value)}
                                    label="Folder Name"
                                />
                                <div style={{ display: 'inline-flex', marginTop: '5px' }}>
                                    <Tooltip title="Clear" arrow placement="bottom">
                                        <IconButton size="small" onClick={() => setParentFolderValue('')}>
                                            <ClearRounded />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Save new folder" arrow placement="bottom">
                                        <IconButton size="small" color="primary" onClick={handleAddParentFolder}>
                                            <SaveRounded />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                <div style={{ display: 'flex', marginTop: '10px' }}>
                                    {loading ? 'Loading...' : <Tree treeData={treeData} onSelect={onSelect} onExpand={onExpand} />}
                                </div>

                                <div>
                                    <span style={{ fontWeight: 'bold' }}>Note:</span> <span style={{fontSize:'11px'}}>Selecting a folder is optional, but if you want your file uploaded to a specific folder, please select it first.</span>
                                </div>

                            </div>
                            <div className="rightPane">
                                <div style={{
                                    display: isMSAccount ? 'flex': 'none',
                                    justifyContent: 'center',
                                    marginBottom: '5px'
                                }}
                                >OR</div>
                                <Dropzone
                                    maxFiles={10}
                                    getUploadParams={uploadFile}
                                    onChangeStatus={handleChangeStatus}
                                    accept="audio/*"
                                    inputContent="Drag Audio Files or Click to Upload"
                                    submitButtonContent="Upload"
                                    styles={{
                                        dropzone: { minHeight: 200, maxHeight: 250 },
                                        dropzoneActive: { borderColor: 'green' },
                                        progressBar: { display: showProgressBar },
                                    }}
                                    inputWithFilesContent="Add More Files"
                                    previewStyle="inline"
                                    autoUpload
                                    ref={dropzoneRef}
                                />
                                <div>{isUploading && <LoadingIndicator />}</div>
                                {uploadProgress > 0 && uploadProgress < 100 && (
                                    <div style={{ marginTop: '10px' }}>
                                        <progress value={uploadProgress} max={100} style={{ width: '100%' }}>
                                            {uploadProgress}%
                                        </progress>
                                        <span>{uploadProgress}%</span>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

        </div>
    );
};
// Define prop types
UploadFile.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    selectedFile: PropTypes.object,
};
export default UploadFile;